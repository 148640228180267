import { useRef, useState } from "react";
import useFadeIn from "../../../../hooks/useFadeIn";
import Button from "../../../../components/Button";
import "./style.css";

export default function Speaker(props) {
  const imgRef = useRef();
  const [showMore, setShowMore] = useState(false);

  const imgClassName = useFadeIn(imgRef, { threshold: [0.75] });

  return (
    <div
      className={props.align === "right" ? "Speaker Speaker__Right" : "Speaker"}
    >
      <div
        ref={imgRef}
        className={imgClassName + " SpeakerImg"}
        data-delay="100"
      >
        <img src={props.img} alt="text" />
      </div>
      <div className="SpeakerDetails">
        <h3
          className={imgClassName + " SpeakerName"}
          data-direction={props.align === "right" ? "left" : "right"}
          data-delay="200"
        >
          {props.name}
        </h3>
        <span
          className={imgClassName + " SpeakerTitle"}
          data-direction={props.align === "right" ? "left" : "right"}
          data-delay="300"
        >
          {props.title}
        </span>
        <div
          className="SpeakerWrap"
          style={{ display: showMore ? "block" : "-webkit-box" }}
        >
          {props.description.map((para, index) => (
            <p
              key={index}
              className={imgClassName + " SpeakerDescrip"}
              data-direction={props.align === "right" ? "left" : "right"}
              data-delay="400"
            >
              {para}
            </p>
          ))}
        </div>
        <Button
          type="button"
          className={imgClassName + " SpeakerWrapShow"}
          onClick={() => setShowMore(!showMore)}
          data-delay="500"
        >
          {showMore ? "Show less" : "Show more"}
        </Button>

        {/* <p
          className={imgClassName + " SpeakerFollow"}
          data-direction={props.align === "right" ? "left" : "right"}
          data-delay="500"
        >
          <span>Follow {props.shortName} — </span>
          <a href="/">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z" />
            </svg>
          </a>
          <a href="/">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z" />
            </svg>
          </a>
          <a href="/">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
            </svg>
          </a>
          <a href="/">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
            </svg>
          </a>
        </p> */}
      </div>
    </div>
  );
}
